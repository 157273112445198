import React, { useState, useEffect } from "react";

const Toast = ({ message, type, onClose }) => {
    // Auto-close the toast after a delay
    useEffect(() => {
        const timer = setTimeout(() => {
            onClose();
        }, 3000); // 3 seconds
        return () => clearTimeout(timer);
    }, [onClose]);

    const getToastColor = () => {
        switch (type) {
            case "success":
                return "bg-green-500";
            case "error":
                return "bg-red-500";
            case "info":
                return "bg-blue-500";
            case "warning":
                return "bg-yellow-500";
            default:
                return "bg-gray-800";
        }
    };

    return (
        <div className={`fixed top-1 left-1/2 transform -translate-x-1/2 z-50 w-full sm:max-w-xs sm:translate-x-0 sm:left-auto sm:right-2 flex items-center shadow-lg rounded-md p-2 text-white ${getToastColor()}`}>
            <span className="text-sm">{message}</span>
            <button
                onClick={onClose}
                className="ml-auto text-lg font-bold text-white"
            >
                ×
            </button>
        </div>
    );
};

export default Toast;
