import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation files
const resources = {
  en: {
    translation: {
      welcome: "Welcome to our page",
      description: "This page can translate between Indonesian and English."
    },
  },
  id: {
    translation: {
      welcome: "Selamat datang di halaman kami",
      description: "Halaman ini dapat menerjemahkan antara Bahasa Indonesia dan Bahasa Inggris."
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React already escapes values to prevent XSS
  },
});

export default i18n;
