import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import NotFound from "./notfound";
import Home from "./Home"
// import NotFound from "./pages/NotFound";
import VacancyList from "./Vacancy";
import VacancyForm from "./Vacancy/form"
function App() {
  return (  
    <Router> 
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/vacancy/list" component={VacancyList} />
        <Route exact path="/form/apply/:idVac/:noVac" component={VacancyForm} />
        <Route component={NotFound} />
      
      </Switch>
    </Router>
  );
}

export default App;


