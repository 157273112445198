import React, { useState, useEffect ,useRef} from "react";
import Navbar from "../components/Navbar";
import './style.css'
import CryptoJS from 'crypto-js';
import axios from "axios";
import Swal from "sweetalert2";
import { useParams,useHistory } from "react-router-dom";
import moment from "moment";
import { Button } from "react-bootstrap";
import he from 'he';

function VacancyList() {
  const history = useHistory();
  const [vacancyList, setVacancyList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [showVideo, setShowVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVacancy, setSelectedVacancy] = useState([]);

  const [deptList, setDeptList] = useState([]);
  const [locList, setLocList] = useState([]);
  const [positionTypeList, setPositionTypeList] = useState([]);
  // Ref for scrolling to vacancy list  
  const vacancyListRef = useRef(null);  
  // / State for selected filters  
  const [selectedDept, setSelectedDept] = useState("");  
  const [selectedLoc, setSelectedLoc] = useState("");  
  const [selectedJobType, setSelectedJobType] = useState(""); 

  const handleClickOutside = (e) => {
    if (e.target.id === 'modal-overlay') {
        setShowModal(false);
    }
  };

  const getListVacancy = async () => {
    // setLoading(true);
    try {
   
      const response = await axios.get(`https:/apicareer.ashleyhotelgroup.co.id/api/get/list/vacancy`);
      console.log(response.data)
      if (response.data.success) {
        console.log(response.data.data)
        setVacancyList(response.data.data)
        
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    } finally {
      // setLoading(false);
    }
  };

  const getListDept = async () => {
    // setLoading(true);
    try {
   
      const response = await axios.get(`https:/apicareer.ashleyhotelgroup.co.id/api/get/list/dept`);
     
      if (response.data.success) {
        // console.log(response.data.data)
        setDeptList(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    } finally {
      // setLoading(false);
    }
  };

  const getListLoc = async () => {
    // setLoading(true);
    try {
   
      const response = await axios.get(`https:/apicareer.ashleyhotelgroup.co.id/api/get/list/loc`);
      console.log(response.data)
      if (response.data.success) {
        console.log(response.data.data)
        setLocList(response.data.data)
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    } finally {
      // setLoading(false);
    }
  };

  const getListJobType = async () => {
    // setLoading(true);
    try {
   
      const response = await axios.get(`https:/apicareer.ashleyhotelgroup.co.id/api/get/list/type/job`);
      // console.log(response.data)
      if (response.data.success) {
        
        setPositionTypeList(response.data.data)
        
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    } finally {
      // setLoading(false);
    }
  };

  const openDetailVacancy = async (vac) =>{
    console.log(vac)
    setSelectedVacancy(vac)
    setShowModal(true)
  }

  // Function to encode to URL-safe Base64
  const replaceGaring = (str) => {
    return str.replace(/\//g, '_') // Replace / with _
  };

  // Filtered vacancy list based on selected filters   
  const filteredVacancyList = vacancyList.filter(vacancy => {  
    const matchesDept = selectedDept ? vacancy.dept_name === selectedDept : true; // Compare with dept_name  
    const matchesLoc = selectedLoc ? vacancy.loc === selectedLoc : true; // Compare with loc  
    const matchesJobType = selectedJobType ? vacancy.type_job === selectedJobType : true; // Compare with type_job  
    return matchesDept && matchesLoc && matchesJobType;  
  }); 
  
  // Function to handle search button click  
  const handleSearchClick = () => {  
    // Scroll to the vacancy list  
    if (vacancyListRef.current) {  
      vacancyListRef.current.scrollIntoView({ behavior: "smooth" });  
    }  
  };  

  const doRedirectToApply = async (vac) => {
    try {
        console.log(vac); // Log the vacancy object for debugging

        // Ensure vac has the expected properties
        if (!vac || !vac.id_vacancy || !vac.no_vac) {
            throw new Error("Vacancy object is missing required properties");
        }

        // Get the secret key from environment variables
        // const secretKey = process.env.REACT_APP_SECRET_KEY;

        // if (!secretKey) {
        //     throw new Error("Secret key is not defined");
        // }

        // Log the ID values
        console.log("ID Vacancy:", vac.id_vacancy);
        console.log("No Vacancy:", vac.no_vac);

        // Encrypting with CryptoJS
        var secretKey = 'Yia32UQSgaRgeUjrRJdwds+SE2XgUkSEA0j2sR4xnjo='; // Use the same key
        var iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
        var idVac = CryptoJS.AES.encrypt(vac.id_vacancy.toString(), CryptoJS.enc.Base64.parse(secretKey), { iv: iv }).toString();

        // // Combine IV and encrypted data and encode in Base64 URL-safe format
        var combinedVac = iv.concat(CryptoJS.enc.Base64.parse(idVac)).toString(CryptoJS.enc.Base64).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        let decNoVac = parseInt(vac.no_vac)+4;
        // // console.log(combinedVac)

        // // Redirect to the new URL
        history.push(`/form/apply/${combinedVac}/${decNoVac}`);
        
        // Uncomment if needed
        // setSelectedVacancy(vac);
        // setShowModal(true);
    } catch (error) {
        console.error("Error in doRedirectToApply:", error);
    }
  };

  const sendEmail = async () => {
    // setLoading(true);
    try {
    const response = await axios.get(`https:/apicareer.ashleyhotelgroup.co.id/api/send/email`);
      console.log(response.data)
    } catch (error) {
      console.error("Error fetching departments:", error);
    } finally {
      // setLoading(false);
    }
  };


  useEffect(() => {
    getListDept();
    getListLoc();
    getListJobType();
    getListVacancy();
  }, []);
  
  return (
    <>
    <Navbar/>
   
    <div className="relative h-[50vh] bg-cover bg-center"  style={{ backgroundImage: "url('https://res.cloudinary.com/dwptu3dam/image/upload/c_limit,w_3840/dpr_auto/f_auto/q_auto/v1718394958/Blog/Blog-2024-06-14-teamwork-communication/Copy_of_MC_Copy_-_Image_Optimizer_16_wj9blq?_a=BAVAUaGd0')" }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative  flex flex-col items-center py-10 max-h-[25vh] text-white px-2">
          <h1 className="text-1xl md:text-4xl md:mt-20 sm:mt-12 font-bold text-center">Current Opportunities</h1>

         
          <div className="mt-5 flex flex-col items-center space-y-4 w-full max-w-4xl">

              <div className="flex items-center bg-white rounded-full px-4 py-2 shadow-md w-full">
                  <i className="fas fa-search text-gray-500"></i>
                  <input type="text" placeholder="Find your dream job" className="ml-2 text-base w-full bg-transparent focus:outline-none text-gray-700" />
              </div>

              <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full">
                <div className="relative w-full">
                  <select value={selectedDept} onChange={(e) => setSelectedDept(e.target.value)} className="appearance-none w-full text-base bg-white text-gray-400 rounded-full px-5 py-2 shadow-md">
                    <option>Department</option>
                    <option value="">Department</option>  
                    {deptList.map((dept) => (  
                      <option key={dept.id_dept} value={dept.dept_name}>  
                        {dept.dept_name}  
                      </option>  
                    ))}  
                  </select>
                  <i className="fas fa-th-large absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
                </div>
                <div className="relative w-full">
                  <select value={selectedLoc} onChange={(e) => setSelectedLoc(e.target.value)} className="appearance-none text-base w-full bg-white text-gray-400 rounded-full px-5 py-2 shadow-md">
                    <option>Location</option>
                    {locList.map((location) => (  
                      <option key={location.id_hotel} value={location.loc}>  
                        {location.loc}  
                      </option>  
                    ))} 
                  </select>
                  <i className="fas fa-map-marker-alt absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
                </div>
                <div className="relative w-full">
                  <select value={selectedJobType} onChange={(e) => setSelectedJobType(e.target.value)} className="appearance-none w-full text-base bg-white text-gray-400 rounded-full px-5 py-2 shadow-md">
                    <option>Job Types</option>
                    {positionTypeList.map((jobType) => (  
                      <option key={jobType.id_type_job} value={jobType.type_job}>  
                        {jobType.type_job}  
                      </option>  
                    ))}  
                  </select>
                  <i className="fas fa-briefcase absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
                </div>
                <button onClick={handleSearchClick} className="flex items-center text-base bg-amber-500 text-gray-700 rounded-full px-4 py-2 shadow-md w-full md:w-auto">
                  <i className="fas fa-search mr-2"></i> Search
                </button>
              </div>

          </div>
      </div>
    </div>

    <div className="relative flex flex-col min-h-screen py-auto">
      {/* Background with image */}
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `url('https://lh3.googleusercontent.com/1OLum9SPOSfNU7-CRU5nLlILtAg44osKP6I0npR6v3cnjmQvshndaD39csH9gaYoMXw=w1024-h500')`,
          backgroundRepeat: "repeat",
          opacity: 0.1, // Adjust the overlay intensity
        }}
      >
      </div>

      {/* Color Overlay */}
      <div className="absolute inset-0 bg-[#f6eedd] opacity-60" ref={vacancyListRef}></div>
        <div className="w-full md:w-8/12 mx-auto px-2">
          <h1 className="text-center text-lg md:text-xl font-bold mb-6 mt-12">
            {filteredVacancyList.length} open position in various categories
          </h1>
          {/* Vacancy Container */}
          <div className="vacancy-container mb-20">
            {filteredVacancyList.length === 0 ? (
              <p className="text-center text-gray-600 my-5">No Vacancy</p>
            ) : (
              filteredVacancyList.map((vacancy, index) => (
              <div onClick={() => openDetailVacancy(vacancy)} key={index} className="bg-white job-card flex items-center  p-4 shadow-lg rounded-lg mb-4 relative">
          
                {/* Left Content */}
                <div className="flex-1 pr-5">
                  <p className="text-gray-500 flex flex-wrap gap-2 md:gap-4 text-xs md:text-sm mb-3 -mt-4">
                    <span className="flex items-center">
                      <i className="far fa-address-book mr-1"></i>
                      {vacancy.dept_name}
                    </span>
                    <span className="flex items-center">
                      <i className="far fa-calendar-check mr-1"></i>
                      {vacancy.type_job}
                    </span>
                    <span className="flex items-center">
                      <i className="fas fa-map-marker-alt mr-1"></i>
                      {vacancy.hotel_name}
                    </span>
                  </p>
                  <h2 className="text-lg md:text-lg font-bold mb-1">
                    {vacancy.title_job}
                  </h2>
                  <div className="flex items-center text-sm text-gray-600">
                    <span className="flex items-center mr-4">
                      <i className="fas fa-user mr-1"></i>
                      {vacancy.curr_apply || 0} Applicant
                    </span>
                    <span className="flex items-center">
                      <i className="fas fa-calendar-check mr-1"></i>
                      {moment(vacancy.end_ads).format("LL")}
                    </span>
                  </div>
                </div>

                {/* Right Image */}
                <img
                  src={vacancy.img1}
                  alt={vacancy.title_job}
                  className="w-16 h-16 md:w-24 md:h-24 rounded-lg object-cover"
                />
              </div>

              ))
            )}
          </div>

        </div>
    </div>

    {/* Show Videos */}
      {showVideo && (
        <div  style={{zIndex: 2}} className="fixed inset-0 bg-gray-800 bg-opacity-85 flex items-center justify-center z-70" onClick={() => setShowVideo(false)}>
          <div className="max-w-7xl mx-auto p-6 shadow-md rounded-md relative">
            <button className="absolute top-2 right-2 text-gray-600" onClick={() => setShowVideo(false)}>
              <i className="fas fa-times"></i>
            </button>
            <video controls className="w-full h-auto">
              <source src={selectedVideo} type="video/mp4" /> {/* Adjust type based on your video format */}
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    {/* End Show Videos */}

    {/* Modal Detail Here */}
      {showModal && (
      <div
        id="modal-overlay"
        className="fixed inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center z-50"
        onClick={handleClickOutside}
      >
      <div className="bg-white max-w-screen-xl mx-auto p-10 shadow-lg rounded-lg relative max-h-[92vh] w-full overflow-y-auto">
        <button
          className="absolute top-4 right-4 text-gray-600 text-2xl"
          onClick={() => setShowModal(false)}
        >
          <i className="fas fa-times"></i>
        </button>

        {/* Video Section */}
        <div className="relative">
          <video controls className="w-full h-[240px] md:h-[360px] bg-gray-400 rounded-lg">
            <source
              src={selectedVacancy.video_thumbnail}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="absolute top-4 left-4 bg-gray-800 bg-opacity-75 px-2 py-1 rounded text-white">
            <p className="text-sm">{selectedVacancy.dept_name}</p>
          </div>
        </div>

        {/* Job Details */}
        <div className="mt-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            {selectedVacancy.title_job}
          </h2>
          <p className="text-sm text-gray-600">
            <i className="far fa-address-book mr-1"></i>
            {selectedVacancy.type_job} <i className="far fa-address-book mx-1"></i>
            <span className="text-gray-800">{selectedVacancy.hotel_name}</span>
            <i className="far fa-address-book mx-1"></i>
            <span className="text-gray-800">{selectedVacancy.loc}</span>
          </p>
        </div>

        {/* Images Section */}
        <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6">
          <img
            src={selectedVacancy.img1}
            alt="Ashley-hotel-group-img1"
            className="w-full h-60 object-cover rounded-md shadow-md"
          />
          <img
            src={selectedVacancy.img2}
            alt="Ashley-hotel-group-img2"
            className="w-full h-60 object-cover rounded-md shadow-md"
          />
          <img
            src={selectedVacancy.img3}
            alt="Ashley-hotel-group-img3"
            className="w-full h-60 object-cover rounded-md shadow-md"
          />
        </div>

        {/* Description Sections */}
        <div className="mt-8">
          <h3 className="text-base font-semibold text-gray-900 mb-2 italic">
            Description :
          </h3>
           <div className="text-base" dangerouslySetInnerHTML={{ __html: selectedVacancy.caption_job }} />
        </div>

        <div className="mt-6">
          <h3 className="text-base font-semibold text-gray-900 mb-2 italic">
            Qualification:
          </h3>
          <div
            className="text-base"
            dangerouslySetInnerHTML={{
              __html: selectedVacancy.qualification_job,
            }}
          />
        </div>

        {/* Footer Section */}
        <div className="mt-10 flex justify-between items-center">
          {/* Social Media Buttons */}
          <div className="flex space-x-4">
            <button
              onClick={() =>
                window.open(selectedVacancy.ig_link, "_blank").focus()
              }
              className="text-gray-600 text-xl"
            >
              <i className="fab fa-instagram"></i>
            </button>
            <button
              onClick={() =>
                window.open(selectedVacancy.linkdin, "_blank").focus()
              }
              className="text-gray-600 text-xl"
            >
              <i className="fab fa-linkedin"></i>
            </button>
          </div>

          {/* Action Buttons */}
          <div className="flex space-x-4">
            <button
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-full text-base font-bold"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
            <button
              className="px-6 py-2 bg-yellow-500 text-gray-800 rounded-full text-base font-bold"
              onClick={() => doRedirectToApply(selectedVacancy)}
            >
              Apply Now
            </button>
          </div>
        </div>
      </div>
      </div>
      )}
    {/* End Modal Detail Here */}

    </>
  );
}

export default VacancyList;
