import React, { useState, useEffect } from "react";
import Navbar from "../components/NavHome";
// import './style.css'
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import axios from "axios";
import Swal from "sweetalert2";
import { useParams,useHistory } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet";
import ass1 from "../assets/decor1.png"
import bgmission from "../assets/batik1.png"
import div4img from '../assets/div4.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
// import required modules
import { FreeMode, Pagination } from 'swiper/modules';
function Home() {
  
  const history = useHistory();
  const [div1Data,setDiv1Data]= useState([]);
  const [div2Data,setDiv2Data]= useState([]);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const getDiv1 = async () => {
    // setLoading(true)
    try {
      const response = await axios.get(`https:/apicareer.ashleyhotelgroup.co.id/api/get/div1`);
      console.log(response.data);
      if (response.data.success){
        setDiv1Data(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      console.log('Holaaaa');
    }
  } 

  const getDiv2 = async () => {
    // setLoading(true)
    try {
      const response = await axios.get(`https:/apicareer.ashleyhotelgroup.co.id/api/get/div2`);
      console.log(response.data);
      if (response.data.success){
        setDiv2Data(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      console.log('Holaaaa');
    }
  } 


  useEffect(() => {
    AOS.init();
    getDiv1();
    getDiv2();
    // Function to scroll the section into view when it is partially visible
    const scrollHandler = () => {
      const sections = document.querySelectorAll("section");
      const windowHeight = window.innerHeight;

      sections.forEach((section) => {
        const { top, bottom } = section.getBoundingClientRect();

        // Check if the section is partially visible
        if (top < windowHeight / 2 && bottom > windowHeight / 2) {
          section.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      });
    };

    // Add scroll event listener
    window.addEventListener("scroll", scrollHandler);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
    
  }, []);
  
  return (
    <>
    <Helmet>
      <title>Ashley Hotel Group Carrer - Continuous Growth and Stability: A Grateful Journey at Ashley Hotel Group Indonesia</title>
      <meta name="description" content="A Grateful Career at Ashley Hotel Group Indonesia."/>
      <meta name="keywords" content="career,loker,hotel,lowongan-kerja,ashley-hotel,prima-hotel-indonesia,ramayana-group"/>
      <meta property="og:title" content="Ashley Hotel Group Carrer - Continuous Growth and Stability: A Grateful Journey at Ashley Hotel Group Indonesia" />
      <meta property="og:description" content="Welcome to Ashley Hotel Group Carrer." />
      <meta property="og:image" content="https://www.yourwebsite.com/og-home-image.jpg" />
      <meta property="og:url" content="https://www.yourwebsite.com/home" />
    </Helmet>

    <Navbar />
        {/* Div 1 */}
        <div id="section" className="h-screen bg-cover bg-center relative" style={{ backgroundImage: `url(${div1Data.bg})` }}>
            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-50"></div>
            {/* Text Container */}
            <div data-aos="fade-up" data-aos-duration="3000" data-aos-anchor-placement="center-center" className="top-0 md:w-1/2 sm:w-full h-full flex flex-col justify-center items-start pl-5 text-white relative z-2">
                <p className="text-[80px] font-bold text-slate-100 md:pl-72 sm:pl-4 mb-4 leading-[0.9]">
                    {div1Data.title}
                </p>
                <p className="text-xl mb-8 md:pl-72 sm:pl-4">
                {t('Experience unparalleled growth and excellence in the world of hospitality.')}
                </p>
                <button onClick={()=>history.push('vacancy/list')} className="bg-white text-black px-6 py-3 rounded-full flex items-center md:ml-72 xs:ml-1">
                    <i className="fas fa-search mr-2"></i> Discover Jobs
                </button>

               
            </div>
        </div>

        {/* Div 2 */}
        <div id="section" className="relative flex items-center justify-center min-h-screen bg-slate-50">
          {/* Content Section */}
          <div className="relative z-2 max-w-7xl mx-auto p-2">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
                  {/* Image Section */}
                  <div data-aos="zoom-in">
                      <img
                          src={div2Data.photo}
                          alt="Group of people posing together in a festive environment with balloons and lights"
                          className="rounded-lg shadow-lg"
                      />
                  </div>

                  {/* Text Section with Background Image */}
                  <div className="relative px-6">
                      <div
                          className="absolute inset-0 bg-cover bg-center -top-60  z-3"
                          style={{
                              width:"100%",
                              height:"100%",
                              backgroundImage: `url(${ass1})`, // Replace with your background image source
                              backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                              backgroundSize: 'auto', // Adjusts the size of the background image
                              opacity: 0.2, // Optional: Adjusts the opacity of the background image
                          }}
                      ></div>
                      <div  className="relative z-2">
                          <h2 data-aos="fade-right" className="text-[50px] leading-[1] font-semibold text-[#15272C] mb-4">
                              {div2Data.title}
                          </h2>
                          <p  data-aos="fade-left" className="text-gray-700 leading-[1.8] text-xl text-justify">
                              {div2Data.caption}
                          </p>
                      </div>
                  </div>
              </div>
          </div>
        </div>

        {/* Div 3 */}
        <div className="relative flex flex-col items-center justify-between py-4 h-auto">
          {/* Background with image */}
          <div className="absolute inset-0"
            style={{
              backgroundImage: `url(${bgmission})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              opacity: 0.08, // Adjust the overlay intensity
            }}></div>

          {/* Color Overlay */}
          <div className="absolute inset-0 bg-[#FDF5E0] opacity-5"></div>

          {/* Content Section */}
          <div className="relative flex flex-col items-center min-h-screen py-auto">
            {/* Title Section */}
            <div className="text-center pt-36" data-aos="flip-left">
              <h1 className="text-4xl font-bold">Our Mission</h1>
              <div className="border-t-2 border-yellow-500 w-16 mx-auto mt-4"></div>
            </div>

            <div data-aos="zoom-in" className="text-justify text-xl pt-12 pb-12 px-16 md:px-44">
              At Ashley Hotel Group, we are driven by our passion for exceptional hospitality. Founded in
              2015, our mission is to build and operate traveler-friendly hotels that offer more than just a
              stay — we create lasting memories. Whether it's budget-friendly accommodations or
              luxurious 4-star experiences, our hotels in Jakarta and Tangerang reflect our commitment
              to quality service.
            </div>

            <div className="text-justify px-16 md:px-44 pb-12" data-aos="zoom-in-up">
              <blockquote className="relative pl-6 border-l-4 border-stone-500 text-2xl text-gray-700 italic">
                <span className="absolute left-0 top-0 transform -translate-x-8 text-2xl text-stone-500">“</span>
                We love serving you, and we believe our people are the core of that promise. Join our
                team and contribute to our continued growth in hospitality as we expand across the
                Jakarta area.
                <span className="absolute right-0 bottom-0 transform translate-x-8 text-4xl text-stone-500">”</span>
              </blockquote>
            </div>

           
           {/* Image Row Section */}
            <div className="grid grid-cols-11 gap-1 mt-10 px-48" data-aos="zoom-out-down">
              {Array.from({ length: 11 }).map((_, index) => (
                <div key={index} className="w-full h-auto">
                  <img
                    src={`https://via.placeholder.com/300x300?text=Image+${index + 1}`}
                    alt={`Hotel Image ${index + 1}`}
                    className="w-full h-auto object-cover rounded-lg shadow-md"
                  />
                </div>
              ))}
            </div>

          </div>
        </div>
        
        {/* Div 4 */}
        <div id="section" className="relative flex items-center justify-center min-h-screen bg-slate-50">
          
          {/* Content Section */}
          <div className="relative z-2 max-w-7xl mx-auto p-2">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1 items-center">
              
              {/* Image Mosaic Layout Section */}
              <div className="grid grid-rows-2 gap-1 max-w-full">
                {/* Top Row */}
                <div className="grid grid-cols-3 gap-1">
                  {/* Large Image on the Left */}
                  <div className="relative col-span-2">
                    <img
                      data-aos="fade-up"
                      src="https://via.placeholder.com/600x300"
                      alt="Large Tile 1"
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg flex flex-col justify-center p-6">
                      <h3 className="text-white text-xl font-bold">Large Tile Title</h3>
                      <p className="text-gray-200 mt-2">
                        Descriptive text for the large image goes here.
                      </p>
                    </div>
                  </div>

                  {/* Small Image on the Right */}
                  <div className="relative col-span-1">
                    <img
                      data-aos="fade-down"
                      src="https://via.placeholder.com/300x300"
                      alt="Small Tile 1"
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg flex flex-col justify-center p-6">
                      <h3 className="text-white text-lg font-bold">Small Tile Title</h3>
                      <p className="text-gray-200 mt-2">Small tile text.</p>
                    </div>
                  </div>
                </div>

                {/* Bottom Row */}
                <div className="grid grid-cols-3 gap-1">
                  {/* Small Image on the Left */}
                  <div className="relative col-span-1">
                    <img
                      data-aos="fade-left"
                      src="https://via.placeholder.com/300x300"
                      alt="Small Tile 2"
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg flex flex-col justify-center p-6">
                      <h3 className="text-white text-lg font-bold">Small Tile Title</h3>
                      <p className="text-gray-200 mt-2">Small tile text.</p>
                    </div>
                  </div>

                  {/* Large Image on the Right */}
                  <div className="relative col-span-2">
                    <img
                      data-aos="fade-right"
                      src="https://via.placeholder.com/600x300"
                      alt="Large Tile 2"
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg flex flex-col justify-center p-6">
                      <h3 className="text-white text-xl font-bold">Large Tile Title</h3>
                      <p className="text-gray-200 mt-2">
                        Descriptive text for the large image goes here.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Text Section with Background Image */}
              <div className="relative px-6">
                <div className="relative z-2">
                  <h2
                    data-aos="fade-right"
                    className="text-[50px] leading-[1] font-semibold text-[#15272C] mb-4"
                  >
                    Growing Together
                  </h2>
                  <p
                    data-aos="fade-left"
                    className="text-gray-700 leading-[1.8] text-xl text-justify"
                  >
                    We believe that growth happens through guidance and shared
                    knowledge. Every day is an opportunity to learn, improve, and
                    achieve new heights together.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* Div 5 */}
        <div className="flex items-center justify-center min-h-screen bg-[#f4efcd]">
            <div className="max-w-8xl mx-auto px-0  flex flex-col md:flex-row-reverse items-center gap-12 ">
                <div className="w-full md:w-1/2 flex justify-end item-center p-2" data-aos="flip-right">
                    <img src="https://placehold.co/600x400" alt="Three people in a business meeting, smiling and discussing something on a laptop" className="rounded-lg shadow-lg"/>
                </div>

                <div className="w-full md:w-1/2 relative">
                    <div className="absolute -top-20 -left-10 w-full h-full flex items-start justify-start opacity-10">

                    <svg width="400" height="400" viewBox="10 10 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0,80 C50,30 150,130 200,80" stroke="#000" strokeWidth="2" fill="none" />
                        <path d="M0,100 C50,50 150,150 200,100" stroke="#000" strokeWidth="2" fill="none" />
                        <path d="M0,120 C50,70 150,170 200,120" stroke="#000" strokeWidth="2" fill="none" />
                        <path d="M0,140 C50,90 150,190 200,140" stroke="#000" strokeWidth="2" fill="none" />
                        <path d="M0,160 C50,130 150,220 200,160" stroke="#000" strokeWidth="2" fill="none" />
                    </svg>

                    </div>
                    <div className="relative pl-5" data-aos="flip-up">
                        <h1 className="text-4xl font-bold mb-4">Bring Your Ideas to Life</h1>
                        <p className="text-lg text-gray-700">
                            Innovation thrives when creativity meets opportunity. At Ashley Hotel Group, we celebrate fresh ideas and diverse perspectives, knowing that the best solutions often come from thinking outside the box. Here, enthusiasm isn't just encouraged—it's essential.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        {/* Div 6 */}
        <div className="relative flex flex-col items-center justify-between min-h-screen py-auto">
          {/* Background with image */}
          <div className="absolute inset-0"
            style={{
              backgroundImage: `url('https://www.wallpaperuse.com/wallp/12-128588_m.jpg')`,
              backgroundRepeat: "repeat",
              backgroundSize: "cover",
              opacity: 0.1, // Adjust the overlay intensity
            }}></div>

          {/* Color Overlay */}
          <div className="absolute inset-0 bg-[#FDF5E0] opacity-60"></div>

          {/* Content Section */}
          <div className="relative flex flex-col items-center min-h-screen py-auto">
            
            {/* Title Section */}
            <div className="mt-16">
              <div className="text-center">
                <h1 className="text-3xl font-bold">Our Hotels</h1>
                <div className="border-t-2 border-yellow-500 w-16 mx-auto mt-4"></div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center md:items-start justify-center w-full gap-6 py-4">
              
              {/* Left Column */}
              <div className="lg:w-1/3 md:text-left mt-12 mb-8 md:mb-0">
                <img
                  alt="Ashley Hotel Logo"
                  className="mx-auto mb-5"
                  height="150"
                  width="150"
                  src="https://placehold.co/150x150"
                />
                <h2 className="text-2xl mt-10 px-4 font-bold">Ashley Wahid Hasyim</h2>
                <p className="text-gray-400 px-4 mt-4">
                  Located in the center of business and entertainment area in Jakarta, Ashley Hotel Wahid Hasyim presents elegant themed rooms for those of you who want to get rid of fatigue while enjoying the atmosphere of the capital.
                </p>
              </div>

              {/* Right Column */}
              <div className="md:w-1/2 mt-12 flex flex-col px-2 items-end text-right">
                <div className="relative">
                  <img
                    alt="Hotel Reception Area"
                    className="rounded-lg shadow-lg"
                    height="400"
                    width="800"
                    src="https://apicareer.ashleyhotelgroup.co.id/storage/hotels/AS3_1.jpg"
                  />
                  {/* Navigation Buttons */}
                  <button className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white text-gray-700 rounded-full p-2 shadow-md">
                    <i className="fas fa-chevron-left"></i>
                  </button>
                  <button className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white text-gray-700 rounded-full p-2 shadow-md">
                    <i className="fas fa-chevron-right"></i>
                  </button>
                </div>
                {/* Pagination Section */}
                <div className="text-center mt-4">
                  <span className="text-grey-400 font-bold">1</span>
                  <span className="text-gray-300">/10</span>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* div footer */}
        <div className="flex flex-col md:flex-row items-center justify-between px-20 py-8 bg-[#f8f5f0]">
            <div className="flex items-center mb-8 md:mb-0">
                <img src="https://placehold.co/150x150" alt="Group of people smiling and looking at each other" className="h-32"/>
            </div>
            <div className="text-center md:text-center">
                <h1 className="text-2xl font-bold text-gray-800">Join Us at Ashley</h1>
                <button className="mt-4 px-6 py-2 bg-green-600 text-white rounded-full">Discover Jobs</button>
                <div className="mt-4 flex justify-center md:justify-center space-x-4">
                    <i className="fab fa-instagram text-2xl text-gray-800"></i>
                    <i className="fab fa-linkedin text-2xl text-gray-800"></i>
                </div>
                <p className="mt-4 text-gray-600">© 2024 Ashley Hotel Group. All Rights Reserved.</p>
            </div>
        </div>
    </>
  );
}

export default Home;
