import React, { useState, useEffect } from "react";
// import './style.css';
import { useParams,useHistory } from "react-router-dom";
function NavHome() {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
        if (window.scrollY > 0) {
            setScrolled(true);
            setIsOpen(false)
        } else {
            setScrolled(false);
            
        }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    
  }, []);

  return (
    
    <nav className={`p-4 max-h-[300px] ${scrolled ? 'bg-[#f4efcd]' : 'bg-transparent'} fixed w-full shadow-sm top-0 z-10`}>
                        <div className="max-w-7xl mx-auto flex justify-between items-center">
                            <div className="flex items-baseline ml-2"  onClick={()=>history.push('/')}>
                                <h1 className={`text-2xl font-bold ${scrolled ? 'text-[#2b4c4c]' : 'text-slate-100'} tracking-10`}>ASHLEY</h1>
                                <span className={`text-md ml-1 tracking-widest ${scrolled ? 'text-[#1d3b3b]' : 'text-slate-200'}`}>Career</span>
                            </div>
                            <div className={`hidden md:flex space-x-4 ${scrolled ? 'text-[#1d3b3b]' : 'text-slate-200'}`}>
                                <a href="/" className="font-bold">Home</a>
                                <a href="vacancy/list" className="font-normal">Jobs</a>
                            </div>
                            <div className="md:hidden">
                                <button onClick={() => setIsOpen(!isOpen)} className="text-[#2b4c4c]">
                                    <i className="fas fa-bars"></i>
                                </button>
                            </div>
                        </div>
                        {isOpen && (
                            <div className="md:hidden mt-2 pl-4 space-y-2 text-[#2b4c4c]">
                                <a href="/" className="block font-bold">Home</a>
                                <a href="vacancy/list" className="block font-normal">Jobs</a>
                            </div>
                        )}
    </nav>
    
  );
}

export default NavHome;