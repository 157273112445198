// src/pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
const NotFound = () => {
    return (
        <body class="flex items-center justify-center h-screen bg-[#fdf8e4]">
        <div class="text-center">
        <div class="flex justify-center mb-4">
            <img alt="Cartoon character with X eyes and tongue out" class="w-40 h-40" height="150" src="https://storage.googleapis.com/a1aa/image/OfX4SNcWYV3WZCWVbZhkfgmnorCgsfPOxaVMdDrAQFEajOpnA.jpg" width="150"/>
        </div>
        <h1 class="text-4xl font-bold text-orange-500">
            404
        </h1>
        <h2 class="text-2xl font-bold text-orange-500">
            ERROR!
        </h2>
        <p class="text-lg text-orange-500 mt-2">
            Sorry, the page not found
        </p>
        <Link to="/">
        <button class="mt-4 px-6 py-2 bg-orange-500 text-white rounded-full">
            BACK TO HOME
        </button>
        </Link>
        </div>
        </body>
    );
};

export default NotFound;